import { useReCaptcha } from "next-recaptcha-v3";
import { useState } from "react";
import { submitNewsletterSubscription } from "@/app/(sites)/_api/newsletter/submit-newsletter-subscription";
import { useServerConfiguration } from "@/contexts/server-configuration/server-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, pending, failed } from "@/services/utilities/fetchable";

export function useNewsletterSignup(initialState: Fetchable = initial()) {
  const { executeRecaptcha } = useReCaptcha();
  const { domain, id: siteId } = useSite();
  const { newsletterApiDomain } = useServerConfiguration();

  const [submissionState, setSubmissionState] =
    useState<Fetchable>(initialState);

  function reset() {
    setSubmissionState(initial());
  }

  const submit = async (email: string) => {
    setSubmissionState(pending());

    const recaptchaToken = await executeRecaptcha(
      `${domain.replace(".", "_")}_newsletter_signup`,
    );

    if (!recaptchaToken) {
      setSubmissionState(failed("No reCAPTCHA token received."));
    }

    setSubmissionState(
      await submitNewsletterSubscription({
        email,
        newsletterApiDomain,
        recaptchaToken,
        siteId,
      }),
    );
  };

  return {
    reset,
    submissionState,
    submit,
  };
}
