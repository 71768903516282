import clsx from "clsx";
import type { FormEvent } from "react";
import { useCallback } from "react";
import type { NewsletterModel } from "@/app/(sites)/_api/newsletter/newsletter.types";
import { SubmitButton } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { CheckboxWithLabel } from "@/components/Checkbox/CheckboxWithLabel.component";
import { Email } from "@/components/Input/Email/Email.component";
import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useFormValidation } from "@/hooks/use-form-validation.hook";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type NewsletterSignUpFormProps = {
  readonly model: NewsletterModel;
  readonly isPending: boolean;
  readonly onSubmit: (email: string) => void;
  readonly variant?: "primary" | "accent";
};
export function NewsletterSignUpForm({
  model: {
    termLink,
    description,
    showCommercialConsent
  },
  isPending,
  onSubmit,
  variant = "primary"
}: NewsletterSignUpFormProps) {
  const {
    isFormValid,
    checkFormValidity,
    formRef
  } = useFormValidation();
  const termsAndConditionsLink = <Link className={clsx(variant === "accent" && "text-inherit underline hover:brightness-75", variant === "primary" && "text-primary hover:underline")} href={termLink} position="nl-terms" rel="noreferrer" openInNewTab>
      <Translation da="vilkårene" de="die Nutzungsbedingungen" en="the terms and conditions" no="vilkårene" sv="villkoren" />
    </Link>;
  const submit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.

    if (!isFormValid) {
      return;
    }
    const formData = new FormData(formRef.current ?? undefined);
    const emailValue = (formData.get("email") as string);
    onSubmit(emailValue);
  }, [isFormValid, onSubmit, formRef]);
  return <>
      <h1 className="mb-1 text-2xl font-theme-bold tracking-tight lg:text-3xl">
        <Translation model={{
        da: "Tilmeld dig vores nyhedsbrev",
        de: "Zum Newsletter anmelden",
        en: "Sign up for our newsletter",
        no: "Meld deg på vårt nyhetsbrev",
        sv: "Prenumerera på vårt nyhetsbrev"
      }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
      </h1>

      <p className="leading-snug">{description}</p>
      <form className="col-span-full mt-4 flex flex-col justify-between gap-y-2 lg:col-span-3" ref={formRef} onSubmit={submit}>
        <Email disabled={isPending} name="email" required onChange={checkFormValidity} data-sentry-element="Email" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />

        {showCommercialConsent ? <CheckboxWithLabel id="newsletterAcceptTerms" isDisabled={isPending} isRequired={showCommercialConsent} variant={variant} onChange={checkFormValidity}>
            <Translation model={{
          da: inlineJsx`Jeg accepterer ${termsAndConditionsLink} for nyhedsbreve`,
          de: inlineJsx`Ich akzeptiere die ${termsAndConditionsLink}`,
          en: inlineJsx`I accept the ${termsAndConditionsLink}`,
          no: inlineJsx`Jeg aksepterer ${termsAndConditionsLink}`,
          sv: inlineJsx`Jag accepterar ${termsAndConditionsLink}`
        }} />
          </CheckboxWithLabel> : null}

        <div>
          <SubmitButton className="w-full" disabled={!isFormValid} isPending={isPending} variant={variant} data-sentry-element="SubmitButton" data-sentry-source-file="NewsletterSignUpForm.component.tsx">
            <Translation model={{
            da: "Tilmeld",
            de: "Anmelden",
            en: "Subscribe",
            no: "Registrer",
            sv: "Prenumerera"
          }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpForm.component.tsx" />
          </SubmitButton>

          {!showCommercialConsent ? <Link className="mt-0.5 block text-sm hover:underline" href={termLink} openInNewTab>
              <Translation model={{
            da: "Nyhedsbrevsvilkår",
            de: "Newsletter-Bedingungen",
            en: "Newsletter terms",
            no: "Vilkår for nyhetsbrev",
            sv: "Nyhetsbrevsvillkor"
          }} />
            </Link> : null}
        </div>
      </form>
    </>;
}