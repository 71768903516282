import type { SiteId } from "@/contexts/site/models/site.types";
import { clientFetcher } from "@/services/utilities/fetch/client-fetcher";
import { succeeded, failed } from "@/services/utilities/fetchable";
import type { Fetchable } from "@/services/utilities/fetchable";

type SubmitNewsletterSubscriptionProps = {
  readonly siteId: SiteId;
  readonly newsletterApiDomain: string;
  readonly email: string;
  readonly recaptchaToken: string;
};

export async function submitNewsletterSubscription({
  siteId,
  newsletterApiDomain,
  email,
  recaptchaToken,
}: SubmitNewsletterSubscriptionProps): Promise<Fetchable> {
  try {
    const url = new URLSearchParams();

    url.set("siteTag", siteId);
    url.set("recaptchaToken", recaptchaToken);
    url.set("recaptchaVersion", "3");
    url.set("email", email);

    const response = await clientFetcher(
      `${newsletterApiDomain}/api/v2/subscribe?${url.toString()}`,
      {
        method: "POST",
      },
    );

    return response.ok
      ? succeeded()
      : failed(`Unsuccessful request: ${response.statusText}`);
  } catch (error) {
    return error instanceof Error
      ? failed(`Network error: ${error.message}`)
      : failed("Unspecified error");
  }
}
